import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MuiTooltip, { TooltipClassKey, TooltipProps as MuiTooltipProps } from "@material-ui/core/Tooltip";
import { ClassNameMap } from "@material-ui/styles";
import { FC } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      position: "relative",
      maxWidth: 350,
      padding: theme.spacing(1),
      textAlign: "center",
    },
    arrow: {},
  })
);

export type TooltipJSSClassKey = TooltipClassKey | keyof ReturnType<typeof useStyles>;

export type TooltipProps = MuiTooltipProps & {
  className?: string;
  classes?: Partial<ClassNameMap<TooltipJSSClassKey>>;
};

export const Tooltip: FC<MuiTooltipProps> = ({ className, classes: extClasses, ...rest }) => {
  const classes = useStyles({ classes: extClasses });

  return <MuiTooltip className={className} classes={classes} arrow {...rest} />;
};

export default Tooltip;
